import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width:1200
              height:630
            )
          }
        }
      }
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      date(formatString: "ddd DD MMMM")
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
    }
    fragment PropertyCard on WpProperty {
      databaseId
      id
      slug
      title
      propertyAddress {
        hideAddress
        streetAddress
        suburb
        state
        postcode
      }
      propertyData {
        bathrooms
        bedrooms
        type
        carPorts
        carSpaces
        garages
        totalParking
        category
      }
      propertyImages {
        url
        small
        medium
        large
      }
      propertyLocation {
        lat
        long
      }
      propertyInspections {
        startDate
        endDate
      }
      propertyListing {
        searchPrice
        marketingStatus
        displayPrice
        listedDate
        onMarketDate
        auctionDate
        auctionVenue
        officeName
        method
        soldDate
      }
      propertyStaff {
        staffMemberId
        webDisplay
      }
    }
    fragment RentalCard on WpRental {
      databaseId
      id
      slug
      title
      propertyAddress {
        hideAddress
        streetAddress
        suburb
        state
        postcode
      }
      propertyData {
        bathrooms
        bedrooms
        type
        carPorts
        carSpaces
        garages
        totalParking
        category
      }
      propertyImages {
        url
        small
        medium
        large
      }
      propertyInspections {
        startDate
        endDate
      }
      propertyListing {
        searchPrice
        marketingStatus
        displayPrice
        listedDate
        onMarketDate
        officeName
      }
      propertyStaff {
        staffMemberId
        webDisplay
      }
    }
    fragment AgentCard on WpTeam {
      id
      uri
      title
      acf {
        profileImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 812
              )
            }
          }
        }
        role
        email {
          url
          title
        }
        phone {
          url
          title
        }
        skills
        office {
          ... on WpOffice {
            title
            officeDetails {
              id
            }
          }
        }
        otherProfiles {
          agentId
        }
      }
      teamDetails {
        id
        role
        officeId
        officeName
        firstName
        lastName
        email
        mobile
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteSettings {
          acf {
            popup {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                    )
                  }
                }
              }
              title
              content
              subscribeTitle
              thanksTitle
              thanksContent
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery